import './App.css';
import {BrowserRouter as Router, Switch, Route, Link
} from "react-router-dom";
import {Home, About} from './components'


function App() {
  return (
       <Router>
         <div>
          <Link to="/"><button>Home</button></Link>
         </div>
         <div>
          <Link to="/about"><button>About</button></Link>
         </div>
         <div>
          <a href="https://www.pruebas.sibeliusfest.com/angular/profile/1">
           <button>Profile</button>
         </a>
         </div>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
          </Switch>
        </Router>
  );
}

export default App;
