import React from 'react'

export const Home = () => {
    return (
        <div>Home works!</div>
    )
}

export const About = () => {
    return (
        <div>About works!</div>
    )
}